import { Container } from 'inversify';
import ISearchService, { ISearchServiceId } from '@/services/ISearchService';
import SearchService from '@/services/SearchService';
import IPlaceService, { IPlaceServiceId } from '@/services/IPlaceService';
import PlaceService from '@/services/PlaceService';
import { IConfigService, IConfigServiceId } from '@/services/IConfigService';
import AppConfigService from '@/services/AppConfigService';
import { ax, AxiosInstance, IAxiosId } from './axios';
import ILinesService, { ILinesServiceId } from '@/services/ILinesService';
import { LinesService } from '@/services/LinesService';
import { InjectionKey, Plugin, inject } from 'vue';

const __INVERSIFY_SYMBOL__ = Symbol.for('app-inversify-container');
const injectionKey: InjectionKey<Container> = __INVERSIFY_SYMBOL__;

export function createContainer(): Plugin {
    const container = new Container();

    container.bind<AxiosInstance>(IAxiosId).toConstantValue(ax);
    container.bind<IPlaceService>(IPlaceServiceId).to(PlaceService).inSingletonScope();
    container.bind<ISearchService>(ISearchServiceId).to(SearchService).inSingletonScope();
    container.bind<IConfigService>(IConfigServiceId).to(AppConfigService);
    container.bind<ILinesService>(ILinesServiceId).to(LinesService).inSingletonScope();

    return {
        install(app) {
            app.provide(injectionKey, container);
        }
    };
}

export function useContainer() {
    const _container = inject(injectionKey);
    if (!_container) throw new Error('You must call createContainer() first');

    return _container;
}

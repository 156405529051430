import type { DeparturesSearchRequest } from '@/features/departures/models';
import type { TripSearchRequest } from '@/features/trips/models/TripSearchRequest';
import type { GetDeparturesResponseDto, TripDto } from '@/types/webapi';
import type ServiceOptions from './ServiceOptions';

export const ISearchServiceId = Symbol.for('ISearchService');

export default interface ISearchService {
    searchForTrips(request: TripSearchRequest, options?: ServiceOptions): Promise<TripDto | null>;
    searchForMoreTrips(request: TripSearchRequest, options?: ServiceOptions): Promise<TripDto | null>;
    searchForDepartures(request: DeparturesSearchRequest, options?: ServiceOptions): Promise<GetDeparturesResponseDto | null>;
}

import { type DeparturesSearchRequest } from '@/features/departures/models';
import { type TripSearchRequest, WalkSpeedType } from '@/features/trips/models';
import type { GetDeparturesRequestDto, GetDeparturesResponseDto, TripDto, GetTripRequestDto, GetTripResponseDto } from '@/types/webapi';
import { type AxiosInstance, IAxiosId } from '@/plugins/axios';
import { inject, injectable } from 'inversify';
import type ISearchService from './ISearchService';
import type ServiceOptions from './ServiceOptions';
import { useMainStore } from '@/features/common/stores';
import { DateType } from '@/features/common/models';

@injectable()
export default class implements ISearchService {
    @inject(IAxiosId)
    protected axios: AxiosInstance;

    _mainStore: ReturnType<typeof useMainStore>;
    get mainStore(): ReturnType<typeof useMainStore> {
        if (!this._mainStore) {
            this._mainStore = useMainStore();
        }

        return this._mainStore;
    }

    async searchForTrips(searchRequest: TripSearchRequest, options?: ServiceOptions): Promise<TripDto | null> {
        return this.executeSearch(searchRequest, 'get', options);
    }

    async searchForMoreTrips(searchRequest: TripSearchRequest, options?: ServiceOptions): Promise<TripDto | null> {
        return this.executeSearch(searchRequest, 'post', options);
    }

    async executeSearch(searchRequest: TripSearchRequest, method: 'get' | 'post', options?: ServiceOptions): Promise<TripDto | null> {
        const { modes, walkSpeed: walkSpeedType, transferSlack, showTripsWithTransfers, lines } = searchRequest.filters;
        const walkSpeed = this.getWalkSpeed(walkSpeedType);

        const params: GetTripRequestDto = {
            fromPlaceId: searchRequest.from?.id,
            toPlaceId: searchRequest.to?.id,
            dateTime: searchRequest.dateTimeType !== DateType.Now ? searchRequest.dateTime : undefined,
            arriveBy: searchRequest.dateTimeType === DateType.ArriveBy || undefined,
            modes: modes.length ? modes.join(',') : undefined,
            lines: lines.length ? lines.join(',') : undefined,
            walkSpeed,
            transferSlack,
            maximumTransfers: showTripsWithTransfers ? undefined : 1,
            nextPageCursors: searchRequest.nextPageCursors,
            previousPageCursors: searchRequest.previousPageCursors,
            isDebugMode: this.mainStore.isDebugMode !== undefined ? this.mainStore.isDebugMode : undefined
        };

        const response =
            method == 'get'
                ? await this.axios.get<GetTripResponseDto>('/api/v1.0/trips', {
                      signal: options?.signal,
                      params
                  })
                : await this.axios.post<GetTripResponseDto>('/api/v1.0/trips', params, { signal: options?.signal });

        const { data } = response.data;
        if (!data) return null;

        // apply filtering
        if (!this.mainStore.isDebugMode) {
            for(let key in data.tripPatternGroups) {
                data.tripPatternGroups[key] =
                    data.tripPatternGroups[key].filter(p => !p.isExpensiveTripPattern);
            }
        }

        return data;
    }

    async searchForDepartures(
        request: DeparturesSearchRequest,
        options?: ServiceOptions | undefined
    ): Promise<GetDeparturesResponseDto | null> {
        if (!request.quay?.id) throw new Error('Required param missing');

        const { modes } = request.filters;
        const params: GetDeparturesRequestDto = {
            id: request.quay.id,
            dateTime: request.dateTime,
            modes: modes.length ? modes.join(',') : undefined
        };

        const response = await this.axios.get<GetDeparturesResponseDto>('/api/v1.0/departures', {
            signal: options?.signal,
            params
        });

        return response.data;
    }

    private getWalkSpeed(type: WalkSpeedType) {
        switch (type) {
            case WalkSpeedType.Fast:
                return 2.0;
            case WalkSpeedType.Slow:
                return 0.6;
            default:
                return 1.3;
        }
    }
}

import type { PlaceAutocompleteModelDto } from '@/types/webapi';
import type ServiceOptions from './ServiceOptions';
import { type LatLngLiteral } from 'leaflet';

export const IPlaceServiceId = Symbol.for('IPlaceService');

export interface IPlaceServiceOptions extends ServiceOptions {
    origin?: LatLngLiteral;
    stopPlacesOnly?: boolean;
}

export default interface IPlaceService {
    /**
     * Fetches a list of places for the given search term.
     * @param keyword String value to use in search query
     * @param options Additional options to pass to the Places API
     */
    getPlacesAutocomplete(keyword: string, options?: IPlaceServiceOptions): Promise<PlaceAutocompleteModelDto[]>;

    /**
     * Fetches Google Place or StopPlace for the given ID.
     * @param id Google Place ID or StopPlace NSR ID
     * @param options Additional options to pass to the Places API
     */
    getPlaceDetails(id: string, options?: ServiceOptions): Promise<PlaceAutocompleteModelDto | null>;

    /**
     * Fetches Google Place for the given geolocation.
     * @param geolocation Geolocation object
     * @param options Additional options to pass to the Places API
     */
    getPlaceDetailsForGeolocation(geolocation: LatLngLiteral, options?: ServiceOptions): Promise<PlaceAutocompleteModelDto | null>;
}

import { LineDto } from '@/types/webapi';
import ServiceOptions from './ServiceOptions';

export const ILinesServiceId = Symbol.for('ILinesService');

export interface ILinesServiceOptions extends ServiceOptions {
    /**
     * Time for lines to fetch
     */
    dateTime?: Date;
}

export default interface ILinesService {
    /**
     * Fetches all lines from web API.
     * @param options Request options
     */
    getAllLines(options?: ILinesServiceOptions): Promise<LineDto[]>;
}

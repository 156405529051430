import { CustomError } from '@/features/common/models';
import { useContainer } from '@/plugins/inversify';
import IPlaceService, { IPlaceServiceId } from '@/services/IPlaceService';
import { LatLngLiteral } from 'leaflet';
import { useI18n } from 'vue-i18n';

export function usePlaces() {
    const { t } = useI18n();
    const container = useContainer();
    const placeService = container.get<IPlaceService>(IPlaceServiceId);

    async function getPlaceDetailsForGeolocation(latLng: LatLngLiteral, signal?: AbortSignal) {
        // get place details for resolved geolocation
        const place = await placeService.getPlaceDetailsForGeolocation(latLng, { signal });

        if (!place) {
            throw new CustomError(
                'PlaceDetailsError',
                t(
                    'places.resources.errorMessages.geolocationPlaceResolutionError',
                    'We are unable to find an address to search from for your current location.'
                )
            );
        }

        return place;
    }

    return {
        getPlaceDetailsForGeolocation
    };
}

import { GetLinesResponseDto, LineDto } from '@/types/webapi';
import ILinesService, { ILinesServiceOptions } from './ILinesService';
import { inject, injectable } from 'inversify';
import { IAxiosId } from '@/plugins/axios';
import { AxiosInstance } from 'axios';

@injectable()
export class LinesService implements ILinesService {
    @inject(IAxiosId)
    protected axios: AxiosInstance;

    async getAllLines(options?: ILinesServiceOptions | undefined): Promise<LineDto[]> {
        const response = await this.axios.get<GetLinesResponseDto>('/api/v1.0/lines', { signal: options?.signal });

        return response.data.lines;
    }
}

import { type MaybeRef, type Ref, computed, reactive, toValue, watch } from 'vue';
import { type AxiosError } from 'axios';
import { type PlaceAutocompleteModelDto, PlaceType } from '@/types/webapi';
import { useContainer } from '@/plugins/inversify';
import IPlaceService, { IPlaceServiceId } from '@/services/IPlaceService';
import { type VInputWrapper } from '@/features/common/components';
import { isEmpty } from 'lodash-es';
import { useElementSize } from '@vueuse/core';

export function usePlaceAutocomplete(wrapper: Ref<typeof VInputWrapper>) {
    const element = computed(() => wrapper.value?.$el);
    const { width } = useElementSize(element, undefined, { box: 'border-box' });

    return computed(() => ({
        attach: wrapper.value?.$el,
        target: wrapper.value?.$el,
        width: width.value,
        maxWidth: width.value,
        minHeight: 40,
        maxHeight: 300,
        contentClass: 'place-autocomplete__dropdown rounded-lg elevation-4',
        location: 'bottom left',
        locationStrategy: 'static'
    }));
}

export interface UsePlaceAutocompleteModelOptions {
    origin?: MaybeRef<PlaceAutocompleteModelDto | null>;
    stopPlacesOnly?: MaybeRef<boolean | undefined>;
}

export function usePlaceAutocompleteModel(
    originalModel: Ref<PlaceAutocompleteModelDto | null>,
    options?: UsePlaceAutocompleteModelOptions
) {
    const origin = computed(() => toValue(options?.origin));
    const stopPlacesOnly = computed(() => toValue(options?.stopPlacesOnly));

    const container = useContainer();
    const placeService = container.get<IPlaceService>(IPlaceServiceId);

    const model = reactive({
        search: '',
        items: [] as PlaceAutocompleteModelDto[],
        loading: false,
        focused: false
    });

    let getPlaceDetailsAbortController: AbortController | undefined;
    async function loadPlaceDetails(placeId: string) {
        try {
            getPlaceDetailsAbortController = new AbortController();
            model.loading = true;

            originalModel.value = await placeService.getPlaceDetails(placeId, { signal: getPlaceDetailsAbortController.signal });

            model.loading = false;
        } catch (e) {
            const { name } = e as AxiosError;

            if (name === 'CanceledError') return;

            model.loading = false;
            console.error(e as Error);
        }
    }

    let abortController: AbortController | undefined;
    async function loadPlaceAutocompleteSuggestions(searchTerm: string) {
        try {
            abortController = new AbortController();
            model.loading = true;

            const { latitude: lat, longitude: lng } = origin.value?.coordinates || {};
            const _origin = lat && lng ? { lat, lng } : undefined;

            model.items = await placeService.getPlacesAutocomplete(searchTerm, {
                signal: abortController?.signal,
                origin: _origin,
                stopPlacesOnly: stopPlacesOnly.value
            });

            model.loading = false;
        } catch (e) {
            const { name } = e as AxiosError;

            if (name === 'CanceledError') return;

            model.loading = false;
            console.error(e as Error);
        }
    }

    watch(
        originalModel,
        async value => {
            getPlaceDetailsAbortController?.abort();

            if (!value?.id) return;

            // if geolocation i.e. PlaceType.Unknown, or coordinates is null i.e. Google Place, fetch place details
            if (value.type === PlaceType.Unknown || isEmpty(value.coordinates)) {
                await loadPlaceDetails(value.id);
            }
        },
        {
            immediate: true,
            deep: true
        }
    );

    watch(
        () => model.search,
        async value => {
            abortController?.abort();

            // when input field is defocused, the value is reset to '' so we should reset the loading state
            if (!value) {
                model.items = [];
                model.loading = false;
                return;
            }

            await loadPlaceAutocompleteSuggestions(value);
        }
    );

    return model;
}
